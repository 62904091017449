/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap'); */
.formulario {
	width: 500px;
	margin-top: 50px;
	background: #fff;
	border-radius: 5px;
	padding: 20px;
    margin: 50px 20px 50px 20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	text-align: left;
	align-content: space-between;
}

@media screen and (max-width:800px) {
	.formulario {
	  max-width: 80%;
	}
}

.formulario label {
	max-width: 500px;
	display: block;
	font-weight: 800;
	font-size: 14px;
	margin-bottom: 5px;
    margin: 10px;
	color: #1f1f1f;
    text-align: left;
	
    
}


.formulario input,
.formulario textarea {
	font-family: 'Open Sans', sans-serif;
	min-width: auto;
	max-width: 500px;
    height: auto;
	border-radius: 5px;
	border: 2px solid #e2e2e2;
	font-size: 18px;
	padding: 10px;
	color: #1f1f1f;	
	margin: 5px;
	
}

.contenedor-texto {
	display: block;	
	font-weight: 200;
	font-family:sans-serif;
	border: none;
	cursor: pointer;
	width: 90%;
	padding: 30px;
	border-radius: 5px;
	color: #fff;
	font-size: 10px;
	transition: .3s ease all;
	
}


@media screen and (max-width:800px) {
	.contendeor-texto {
	  max-width: 90%;
	}
}
  

.formulario input:focus {
	outline: none;
	border: 2px solid #0085FF;
}

.formulario .error {
	color: #e92b2d;
	font-weight: 600;
	font-size: 12px;
}

.formulario button[type="submit"] {
	display: block;
	background: #0085FF;
	font-weight: 600;
	font-family: 'Open Sans', sans-serif;
	border: none;
	cursor: pointer;
	width: 100%;
	padding: 10px;
	border-radius: 5px;
	color: #fff;
	font-size: 16px;
	transition: .3s ease all;
}

@media screen and (max-width:800px) {
	.footer button[type="submit"] {
	  max-width: 90% ;
	}
  }
  

.formulario button[type="submit"]:hover {
	background: #0051ff;
}

.formulario .exito {
	text-align: center;
	font-size: 14px;
	padding-top: 20px;
	color: #02a802;
}




