
  .logo {
    height: 100%;
    width: auto;
    margin: 20px 20px 20px 30px;        
   
  }

  .titulos {
    color:white;    
  }

  .titulo-texto {
    margin: 25px;  
    font-size: 1.5rem;
    font-weight: 500;
    cursor: pointer;
    color: white;
     
  }

  .titulo-texto:hover {
    color: aquamarine;
  }


  .boton-menu {
    height: 50px;
    width: 150px;    
    margin: 1rem;
    border-radius: 5px;
    border: #fff;
    color:;
    cursor: pointer;
    font-weight: bold;
    background-color:white;  
    display: block;
         
       
  }

  a{
    text-decoration: none;
  }

  .boton-menu:hover {
    background-color: white;
  }

  .imagen-socialmedia {
    height: auto;
    width: 50px;
    cursor: pointer;    
    background-color: white;
    margin: 10px 5px 5px 5px;
    

  }

 
  .contenedor-imagen-socialmedia {
    width: 120px;
    height: auto;
    background-color:white;    
    border-radius: 5px; 
    margin: 10px 10px 10px 10px ;
    display: flex;

  }

  .contenedor-imagen-socialmedia:hover {
    background-color: aquamarine;
  }    


@media screen and (min-width:1000px) {
  .burger {
    display: none;
  }  
}

@media screen and (max-width:1000px) {
  .titulos {
    display: none;
  }  
}



  .links {
    position: absolute;
    top: -1000px;
    left: -2000px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;  
    
    
    
  }

  .links.active { 
    width: 100%;
    display: block;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
    left: 0;
    right: 0;
    text-align: center;
  }



@media screen and (min-width:1000px) {
  .links.active{
    position: initial;
    margin: 0;
        
  }  
}

@media screen and (min-width:1000px) {
  .boton-menu {
    font-size: 1rem;
    color: white;
    display: inline;
    
       

    
  }
}

  

  .nav-icon-1{
    width: 30px;
    height: 30px;
    position: relative;
    transition: .1s;
    margin: 10px 10px;
    cursor: pointer;
    display: inline-block;
  }
  .nav-icon-1 span{
    width: 5px;
    height: 5px;
    background-color: #fff;
    display: block;
    border-radius: 50%;
    position: absolute;
  
  }
  .nav-icon-1:hover span{
    transform: scale(1.2);
    transition: 350ms cubic-bezier(.8, .5, .2, 1.4);
  }
  .nav-icon-1 span:nth-child(1){
    left: 0;
    top: 0;
  }
  .nav-icon-1 span:nth-child(2){
    left: 12px;
    top: 0;
  }
  .nav-icon-1 span:nth-child(3){
    right: 0;
    top: 0;
  }
  .nav-icon-1 span:nth-child(4){
    left: 0;
    top: 12px;
  }
  .nav-icon-1 span:nth-child(5){
    position: absolute;
    left: 12px;
    top: 12px;
  }
  .nav-icon-1 span:nth-child(6){
    right: 0px;
    top: 12px;
  }
  .nav-icon-1 span:nth-child(7){
    left: 0px;
    bottom: 0px;
  }
  .nav-icon-1 span:nth-child(8){
    position: absolute;
    left: 12px;
    bottom: 0px;
  }
  .nav-icon-1 span:nth-child(9){
    right: 0px;
    bottom: 0px;
  }
  .nav-icon-1.open{
    transform: rotate(180deg);
    cursor: pointer;
    transition: .2s cubic-bezier(.8, .5, .2, 1.4);
  }
  .nav-icon-1.open span{
    border-radius: 50%;
    transition-delay: 200ms;
    transition: .5s cubic-bezier(.8, .5, .2, 1.4);
  }
  .nav-icon-1.open span:nth-child(2) {
    left: 6px;
    top: 6px;
  }
  .nav-icon-1.open span:nth-child(4) {
    left: 6px;
    top: 18px;
  }
  .nav-icon-1.open span:nth-child(6) {
    right: 6px;
    top: 6px;
  }
  .nav-icon-1.open span:nth-child(8) {
    left: 18px;
    bottom: 6px;
  }